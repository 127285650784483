<template>
  <v-subheader
    v-if="item.header"
    :key="item.header">
    {{ item.header }}
  </v-subheader>
  <v-divider
    v-else-if="item.divider"
    :key="`divider-${index}`" />

  <v-list-item
    v-else
    :key="`message-${index}`"
    class="py-0"
    align-start>
    <v-list-item-avatar>
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary" />

      <user-avatar
        v-else-if="from && from.name"
        :email="from.email || ''"
        :image="from.photoURL"
        :alt="from.name" />
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title class="darkGray--text caption">
        <b
          v-if="!loading && from && from.name"
          class="body-2">{{ getFirstName(from.name) }}</b>
        <span class="mediumGray--text caption data-time mb-2">{{ item.insertedAt }}</span>
      </v-list-item-title>

      <v-list-item-subtitle
        class="darkGray--text"
        v-html="item.content" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  import UserAvatar from '@/components/ui/UserAvatar'

  export default {
    components: { UserAvatar },
    props: {
      item: {
        type: Object,
        default: () => ({}),
        required: true
      },
      index: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        loading: true
      }
    },
    methods: {
      getFirstName (fullName) {
        return fullName && fullName.split(' ')[0]
      }
    },
    asyncComputed: {
      async from () {
        const doc = this.item && await this.item.from
        this.loading = false
        return doc && doc.data() || { name: '', image: '' }
      }
    }
  }
</script>
